import React from 'react';
import { Link, graphql } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/Layout';
import Post from '../components/Post';
import Pagination from '../components/Pagination';

class BlogIndex extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;
        const posts = data.allMarkdownRemark.edges;

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO htmlClass="weblog" title="Dispatch" />
                <header>
                    <h1>Dispatch</h1>
                    <p>
                        By <Link to="/">Rowan Oulton</Link>
                    </p>
                </header>
                {posts.map(({ node }) => {
                    return (
                        <Post
                            key={node.fields.slug}
                            slug={node.fields.slug}
                            date={node.frontmatter.date}
                            html={node.html}
                        />
                    );
                })}
                <Pagination nextPath="/dispatch/page/2" />
            </Layout>
        );
    }
}

export default BlogIndex;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 10) {
            edges {
                node {
                    html
                    fields {
                        slug
                    }
                    frontmatter {
                        date
                        title
                    }
                }
            }
        }
    }
`;
