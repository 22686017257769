import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const Pagination = ({ prevPath, nextPath }) => (
    <nav role="navigation">
        {prevPath ? (
            <Link to={prevPath}>
                <span className="arrow" aria-hidden="true">
                    ←
                </span>{' '}
                Newer
            </Link>
        ) : null}
        {prevPath && nextPath ? (
            <span style={{ display: 'inline-block', margin: '0 10px' }}>&nbsp;</span>
        ) : null}
        {nextPath ? (
            <Link to={nextPath}>
                Older{' '}
                <span className="arrow" aria-hidden="true">
                    →
                </span>
            </Link>
        ) : null}
    </nav>
);

Pagination.propTypes = {
    prevPath: PropTypes.string,
    nextPath: PropTypes.string,
};

export default Pagination;
