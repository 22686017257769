import React from 'react';
import TimeAgo from 'react-timeago';
import { Link } from 'gatsby';

class Post extends React.Component {
    render() {
        return (
            <article>
                <div dangerouslySetInnerHTML={{ __html: this.props.html }} />
                <footer>
                    <Link to={`/${this.props.slug}`}>
                        <TimeAgo date={this.props.date} />
                    </Link>
                </footer>
            </article>
        );
    }
}

export default Post;
